#contact {
  width: 100vw;
  margin-top: 15rem;
  background-color: rgb(21, 24, 30, 0.9);
  height: 45rem !important;
}

#contact-title {
  font-size: 3.5rem;
  text-align: left;
  margin-top: 8rem;
  margin-bottom: 5rem;
}

#contact-container {
  text-align: left;
  color: aliceblue;
  margin-left: 8vw;
}
#contact-email {
  position: relative;
  width: fit-content !important;
  padding-left: 0.1rem;
  margin-bottom: 5rem !important;
  padding-right: 0.8rem;

  z-index: 1;
}
#contact-email:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    transparent 75%,
    rgba(132, 38, 204, 0.8) 25%
  );
  z-index: -1;
  transition: background 0.5s ease-in-out;
  /* opacity: 0; */
}
#contact-email:hover::before {
  /* opacity: 1; */
  background: linear-gradient(
    180deg,
    transparent 0%,
    rgba(132, 38, 204, 0.8) 100%
  );
  cursor: pointer;
}
#contact-email:hover {
  cursor: pointer;
}

.social-media-col {
  font-size: 1.5rem;
  margin-bottom: 0.6rem;
}
.social-media-col :hover {
  cursor: pointer !important;
}
