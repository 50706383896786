.nav {
  margin-top: 3vh;
  z-index: 10 !important;
  position: relative;
}

.nav-brand {
  margin-right: -10vw !important;
}

.nav-links {
  font-size: 1.1rem;
  margin-left: 16.1%;
}

.nav-link {
  padding-right: 4vw !important;
}

.nav-link:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .nav {
    margin-top: 30;
    width: 80vw !important;
  }
}
