#header {
}
#header-text-group {
  color: aliceblue;
  z-index: 10 !important;
  position: relative;
  margin-top: 15rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -510px;
}

#header-name {
  font-size: 6rem;
  margin-bottom: 2.7rem;
}

#header-lastname {
  color: rgb(175, 124, 215);
}

#header-intro {
}

#header-img {
  width: 100rem;
  height: 51.8rem;
  margin-left: 28rem;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  #header-text-group {
    margin-top: 12rem;
  }
  #header-name {
    font-size: 5rem;
    margin-bottom: 2.7rem;
  }

  #header-img {
    margin-left: 0;
    width: 60rem;
  }
}
