.up {
  position: fixed;
  bottom: 2.5rem;
  font-size: 3rem;
  z-index: 5;
  cursor: pointer;
  color: rgb(175,124,214, 0.80);
  text-align: right !important;
  left: 92.5vw;
}

.up:hover {
    color: rgb(175,124,214, 1);
}


@media only screen and (max-width: 600px)  {
    .up {
        left: 85vw;
      }
}